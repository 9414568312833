<template>
    <!-- Footer -->
    <section class="footer">
        <span style="display: none">{{ version }}</span>
        <a
            :class="classObject('Home')"
            @click.prevent="clickToRoute('Home')"
        >
            <img src="@/assets/icon/home.svg" />
            <img src="@/assets/icon/home-a.svg" />
            <span class="fS white">首頁</span>
        </a>
        <!-- <a
            :class="classObject('Service')"
            @click.prevent="clickToRoute('Service')"
        >
            <img src="@/assets/icon/service.svg" />
            <img src="@/assets/icon/service-a.svg" />
            <span class="fS white">市政服務</span>
        </a> -->
        <a
            :class="classObject('Scanner')"
            @click.prevent="clickToRoute('Scanner')"
        >
            <img src="@/assets/icon/scan.svg" />
            <img src="@/assets/icon/scan-a.svg" />
            <span class="fS white">掃碼</span>
        </a>
        <a
            :class="classObject('Map')"
            @click.prevent="clickToRoute('Map')"
        >
            <img src="@/assets/icon/store.svg" />
            <img src="@/assets/icon/store-a.svg" />
            <span class="fS white">優惠地圖</span>
        </a>
        <a
            :class="classObject('Profile')"
            @click.prevent="clickToRoute('Profile')"
        >
            <img src="@/assets/icon/user.svg" />
            <img src="@/assets/icon/user-a.svg" />
            <span class="fS white">會員</span>
        </a>
    </section>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "Footer",
    props: {
        routeTo: String
    },
    data: function () {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            currentTab: "home",
            tabs: ["Home", "Service", "Scanner", "Map", "Profile"],
            disableTabs: ["Service"]
        };
    },
    computed: {
        ...mapState(["currentPath"]),
        version() {
            return process.env.PACKAGE_VERSION;
        }
    },
    mounted() {
        this.tabs = this.tabs.map((tab) => tab.toUpperCase());
        this.disableTabs = this.disableTabs.map((tab) => tab.toUpperCase());
        this.currentTab = this.currentPath;
    },
    methods: {
        classObject(tab) {
            tab = tab.toUpperCase();
            return {
                icon: true,
                iconButton: true,
                active: this.currentTab.toUpperCase() === tab,
                disabled: this.disableTabs.includes(tab)
            };
        },
        clickToRoute(path) {
            this.currentTab = this.$route.path.slice(1);
            if(!(path.toUpperCase() == this.currentTab.toUpperCase())) {
                this.currentTab = path;
                this.$router.push(`/${path.toLowerCase()}`);
            }
            
        }
    },
    watch: {
        routeTo: function(val) {
            console.log("routeTo: ", val)
        },
        currentPath(val) {
            this.currentTab = val;
        }
    }
};
</script>


